

import { createRouter, createWebHistory } from 'vue-router';

// lazy-loading
const AdminContent = () => import('@/components/admin/AdminContent');
const AdminContents = () => import( '@/AdminContents' );

const UserContents = () => import( '@/UserContents' );
const MainSection = () => import( '../components/MainSection' );
const ContentWrapper = () => import( '../components/ContentWrapper' );
const PopupViewer = () => import('@/components/PopupViewer');

const routes =  [

  // 관리자 화면
  {
    name: 'adminMain',
    path: '/admin',
    // 부모 컴포넌트
    component: AdminContents,
    children: [
      {
        name: 'AdminContentWrapper',
        path: '/admin/:menu/:item', // 이 주소가 오면
        components: { adminContent: AdminContent }, // router-view의 이름 : 해당 뷰에 넣을 컴포넌트
      },
      // ToDo : login 페이지에서 해당 경로로 보이지 않도록 수정
      {
        name: 'defaultAdminContent',
        path: '/admin',
        alias: '/admin/:catchAll(.*)',
        redirect: '/admin/registry/registrylist',
      },
    ],
  },

  // 사용자 화면
  {
    name: 'userMain',
    path: '/',
    component: UserContents,
    children : [
      {
        name: 'contentWrapper',
        path: '/view/:menu/:item',
        components: { content: ContentWrapper },
      },
      {
        name: 'defaultContent',
        path: '/',
        alias: '/:catchAll(.*)',
        components: { content: MainSection },
      },
    ]
  },

  // 팝업 뷰어
  {
    nam: 'popupViewer',
    path: '/popup/:seq',
    component: PopupViewer,
  },

];


const router = createRouter({
  history: createWebHistory(),
  routes,
});


/*router.beforeEach((to, from, next) => {
  if( new Date() < new Date( '2024-01-08T13:00:00' ) ) {
    if (process.env.NODE_ENV !== 'local' && !(to.path === '/'
        || to.path.startsWith('/popup') || to.path.startsWith('/admin'))) {
      alert('개최 기간이 아닙니다');
      router.push('/');
      return;
    }
  }

  next();

});*/

export default router;