import { createStore } from 'vuex';

const store = createStore({
  state: {

    noticeEditorData : {
      noticeDetail : {
        seq : '',
        title : '',
        content : '',
        popupYn : '',
        delYn : '',
        rgstDttm : '',
        mdfrDttm : '',
      },
      isInsert : '',
    },

  },
  mutations: {

    // 공지사항 '글쓰기' 클릭 시 플래그 부여
    setInsertNoticeEditor( state ){
      state.noticeEditorData.isInsert = true;
    },

    // 공지사항 정보
    setNoticeEditorData( state, noticeDetail ) {
      state.noticeEditorData.noticeDetail = noticeDetail;
      state.noticeEditorData.isInsert = false;
    },

    // 공지사항 정보 초기화
    initNoticeEditorData( state ) {
      state.noticeEditorData = {
        noticeDetail : {
          seq : '',
          title : '',
          content : '',
          popupYn : '',
          delYn : '',
          rgstDttm : '',
          mdfrDttm : '',
        },
        flag : '',
      }
    },



  },
  actions: {
  },
  getters: {
  }
});

export default store;
