

import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router'

// Vuex
import store from "@/store";

// grid
import TuiGrid from "vue3-tui-grid"
import 'tui-grid/dist/tui-grid.css';
import "tui-date-picker/dist/tui-date-picker.css";

// Quill Editor
import { quillEditor } from "vue3-quill";

// google-analytics
import VueGtag from 'vue-gtag'

createApp(App)
  .use(VueGtag, { config: { id: 'G-HGMGKFVW58' } } )
  .use( router )
  .use( store )
  .use( TuiGrid )
  .use( quillEditor )
  .mount('#app');


