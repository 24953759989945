
<template>

  <router-view></router-view>

</template>

<script setup>

</script>

<style>

  /* Google Fonts */
  @import './assets/css/google-font.css';
  @import './assets/css/google-font-ko.css';

  /* CSS Global Compulsory */
  @import './assets/vendor/bootstrap/bootstrap.min.css';

  /* CSS Global Icons */
  @import './assets/vendor/icon-awesome/css/font-awesome.min.css';
  @import './assets/vendor/icon-line/css/simple-line-icons.css';
  @import './assets/vendor/icon-etlinefont/style.css';
  @import './assets/vendor/icon-hs/style.css';
  @import './assets/vendor/icon-line-pro/style.css';
  @import './assets/vendor/typedjs/typed.css';
  @import './assets/vendor/slick-carousel/slick/slick.css';
  @import './assets/vendor/hs-megamenu/src/hs.megamenu.css';
  @import './assets/vendor/hamburgers/hamburgers.min.css';
  @import './assets/vendor/animate.css';

  /* CSS Implementing Plugins */
  @import './assets/vendor/slick-carousel/slick/slick.css';
  @import './assets/vendor/dzsparallaxer/dzsparallaxer.css';
  @import './assets/vendor/dzsparallaxer/dzsscroller/scroller.css';
  @import './assets/vendor/dzsparallaxer/advancedscroller/plugin.css';
  @import './assets/vendor/cubeportfolio-full/cubeportfolio/css/cubeportfolio.min.css';
  @import './assets/vendor/hs-bg-video/hs-bg-video.css';
  @import './assets/vendor/fancybox/jquery.fancybox.css';

  /* CSS Unify */
  @import './assets/css/unify-core.css';
  @import './assets/css/unify-components.css';
  @import './assets/css/unify-globals.css';

  /* CSS Customization */
  @import './assets/css/custom.css';
  @import './assets/css/sub-custom.css';

</style>
